import { hideAdvisorWidget, showAdvisorWidget } from '../utils/advisor'

export function hideChatWidget() {
  const { Intercom } = window
  if (Intercom) {
    Intercom('update', { hide_default_launcher: true })
    return true
  }
  return false
}

export function openChatWidget() {
  const { Intercom } = window
  if (Intercom) {
    Intercom('update', { hide_default_launcher: false })
    Intercom('show')
  }
}

function handleAdvisorCta() {
  // On touch devices on catalog pages, widget is displayed inside the floating CTA at the bottom of the screen once the user has scrolled
  const advisorCtaMobile = document.getElementById('advisor-widget-mobile-container')
  if (advisorCtaMobile)
    window.showElementsAsync(
      'advisor-widget-mobile-container',
      'advisor-widget',
      'Product/advisor/AdvisorWidget',
      {
        small: true,
      },
      { ignoreToaster: true }
    )

  // Widget at the bottom right of the screen
  const advisorCtaFixed = document.getElementById('advisor-widget-fixed-container')
  if (advisorCtaFixed)
    window.showElementsAsync(
      'advisor-widget-fixed-container',
      'advisor-widget',
      'Product/advisor/AdvisorWidget',
      {},
      { ignoreToaster: true }
    )
}

window.addEventListener('DOMContentLoaded', () => {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'i2r26s4i',
    hide_default_launcher: true,
  }
  ;(function () {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/i2r26s4i'
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()

  setTimeout(() => {
    document.querySelectorAll('[data-chat-widget-cta]')?.forEach((cta) => {
      cta.addEventListener('click', () => {
        openChatWidget()
        hideAdvisorWidget()
      })
    })
  }, 800)

  handleAdvisorCta()
  showAdvisorWidget()
})
