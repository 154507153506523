import { slugify } from './string'
import { getCurrentLocale } from './translation'

const { DOMAIN_NAME } = process.env
const { PUBLIC_DOMAIN_NAME } = process.env

const LOCALISED_MY_PRODUCTS_URLS = {
  fr: '/mon-compte/mes-annonces',
  en: '/en/my-account/my-listings',
  es: '/es/mi-cuenta/mis-anuncios',
  it: '/it/mio-account/mio-annunci',
}

const LOCALISED_MY_SALES_URLS = {
  fr: '/mon-compte/mes-ventes',
  en: '/en/my-account/my-sales',
  es: '/es/mi-cuenta/mis-ventas',
  it: '/it/mio-account/mio-vendite',
}

const LOCALISED_MY_ORDERS_URLS = {
  fr: '/mon-compte/mes-commandes',
}

const LOCALISED_MY_NEGOTIATIONS_URLS = {
  fr: '/mon-compte/mes-negociations',
  en: '/en/my-account/my-negotiations',
  es: '/es/mi-cuenta/mis-negociaciones',
  it: '/it/mio-account/mio-negoziazioni',
}

const LOCALISED_MY_INFOS_URLS = {
  fr: '/mon-compte/mes-infos',
  en: '/en/my-account/my-info',
  es: '/es/mi-cuenta/mis-info',
  it: '/it/mio-account/mio-info',
}

const LOCALISED_MY_WISHLIST_URLS = {
  fr: '/mon-compte/mes-favoris',
  en: '/en/my-account/my-favourites',
  es: '/es/mi-cuenta/mis-favoritos',
  it: '/it/mio-account/mio-preferiti',
}

const LOCALISED_MY_SAVED_SEARCHES_URLS = {
  fr: '/mon-compte/mes-recherches',
}

export const getWebSiteUrl = (pathAndQuery = '') => {
  const optionalSlash = pathAndQuery.substring(0, 1) === '/' ? '' : '/'

  return pathAndQuery ? `https://${DOMAIN_NAME}${optionalSlash}${pathAndQuery}` : `https://${DOMAIN_NAME}`
}

export const getHomePageUrl = () => `https://${DOMAIN_NAME}`

export const getProductURL = (slug, position = null) =>
  Number.isInteger(position)
    ? `https://${DOMAIN_NAME}/produit/${slug}?pos=${position}`
    : `https://${DOMAIN_NAME}/produit/${slug}`

export const getSellURL = () => `https://${DOMAIN_NAME}/revendre-un-produit`

export const getPublicURL = (uri, optionalResolution = null, preserveRatio = false) => {
  if ((uri || '').startsWith('http')) return uri

  const resolutionParameterName = preserveRatio === true ? 'max' : 'size'

  return optionalResolution
    ? `${PUBLIC_DOMAIN_NAME}${uri}?${resolutionParameterName}=${optionalResolution}`
    : `${PUBLIC_DOMAIN_NAME}${uri}`
}

export const removePublicURL = (uri) => uri.replace(PUBLIC_DOMAIN_NAME, '')

export const getSearchURL = (query) => `https://${DOMAIN_NAME}/recherche/${slugify(query)}`

export const getLoginUrl = () => `https://${DOMAIN_NAME}/se-connecter`

export const getMyProductsUrl = () => getWebSiteUrl(LOCALISED_MY_PRODUCTS_URLS[getCurrentLocale()])
export const getMySalesUrl = () => getWebSiteUrl(LOCALISED_MY_SALES_URLS[getCurrentLocale()])
export const getMyOrdersUrl = () => getWebSiteUrl(LOCALISED_MY_ORDERS_URLS.fr)
export const getMyMessagesUrl = () => getWebSiteUrl(LOCALISED_MY_NEGOTIATIONS_URLS[getCurrentLocale()])
export const getMyInfosUrl = () => getWebSiteUrl(LOCALISED_MY_INFOS_URLS[getCurrentLocale()])
export const getMyWishlistUrl = () => getWebSiteUrl(LOCALISED_MY_WISHLIST_URLS[getCurrentLocale()])
export const getMySavedSearchesUrl = () => getWebSiteUrl(LOCALISED_MY_SAVED_SEARCHES_URLS.fr)

export const getSAVUrl = () => 'https://support.campsider.com/hc/fr/requests/new'
/**
 * Generate the categories / equipments / search URL.
 *
 * For SEO purpose we want that the categorySlug and the page information to be indexable by the bots.
 * But when the user ask for specific filters information (brand, color etc.) the url will contain
 * the /filers parts that will be used to disallowed bots indexing
 *
 * @param {string[]} slugs - The C0, C1 and C2 slugs. (C1 and C2 are optionals)
 * @param {number} page
 * @param {string} filters
 *
 * @returns {string}
 */
export const getCategoriesEquipmentsOrSearchURL = (slugs, page = 1, filters = '') => {
  // Concat all slugs
  const slugPart = `${slugs.join('/')}`

  // No filter and default page / Bots allowed
  if (!filters && page <= 1) return getWebSiteUrl(`/${slugPart}`)

  // No filter and a specific page  / Bots allowed
  if (!filters && page > 1) return getWebSiteUrl(`/${slugPart}?page=${page}`)

  // Filters with no page / Bots disallowed (we add the /filters?XXXXX to the end of the URL)
  if (page <= 1) return getWebSiteUrl(`/${slugPart}/filters?${filters}`)

  // Filters and page  / Bots disallowed (we add the /filters?XXXXX to the end of the URL)
  return getWebSiteUrl(`/${slugPart}/filters?${filters}&page=${page}`)
}

export const getFilteredProductPageUrl = (trackingSlug, slug) => getWebSiteUrl(`/${trackingSlug}/${slug}`)

export const getSellerUrl = (sellerId) => getWebSiteUrl(`/boutique/${sellerId}`)

export const getNewArticles = () => `https://${DOMAIN_NAME}/nouveautes`

export const containsQueryParam = (paramName) => {
  const url = new URL(window.location.href)
  return url.searchParams.has(paramName)
}

export const addParamToCurrentURL = (key, value) => {
  const url = new URL(window.location.href)

  url.searchParams.set(key, value)
  window.history.pushState({}, '', url)
}

export const addParamToURL = (key, value, url) => {
  if (typeof url === 'string') url = new URL(url)
  if (!url) url = new URL(window.location.href)

  url.searchParams.set(key, value)
  return url.href
}

export const removeParamFromCurrentURL = (key) => {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  window.history.pushState({}, '', url.href)
}

export const removeParamFromURL = (key, url) => {
  if (typeof url === 'string') url = new URL(url)
  if (!url) url = new URL(window.location.href)

  url.searchParams.delete(key)
  return url.href
}
