const DEFAULT_MESSAGE =
  'Une erreur inconnue a eu lieu, merci de réessayer ou de contacter notre support'

/**
 * This function parse an error from axios and return the error's message or a default one
 *
 * @param {{}} error - The error from axios
 * @return {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function handleAxiosError(error) {
  // Error generate from the API
  if (error.response) {
    console.warn('API Error', error)
    const {
      response: {
        data: { reason, message },
      },
    } = error
    return `${reason || message || DEFAULT_MESSAGE}`
  }
  // No error from API
  if (error.request) {
    console.warn('Request Error', error)
    return `${DEFAULT_MESSAGE}`
  }

  // Other errors
  console.warn('Unknown Error', error)

  const reason = error.message || DEFAULT_MESSAGE
  return `${reason}`
}
